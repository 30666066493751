import { React } from 'react';
import backgroundImage from '../../Assets/img/login-bg.png';
import { useNavigate } from 'react-router-dom'; // Assuming you are using React Router for navigation

import { IoMailSharp } from "react-icons/io5";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";

function ForgotPassword() {
    let OTP = null;
    let PASSWORD = null;

    const navigate = useNavigate();

    const updatePassword = () => {
        document.getElementById("registrationVerifyErrorLog").style.display = "none";
        document.getElementById("registrationVerifyErrorLog").innerText = "";
        document.getElementById("registrationAccountVerifyButton32").disabled = true;

        const dataFromUser = { email: document.getElementById('emailValue').value, updatedpassword: document.getElementById("passwordInputFieldIMP").value };

        fetch(process.env.REACT_APP_BACKEND_DOMAIN + '/updatepassword', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(dataFromUser)
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            
            if(data.message === "SUCCESS") {
                document.getElementById("registrationAccountVerifyButton32").disabled = false;
                PASSWORD = document.getElementById("passwordInputFieldIMP").value;
            }else 
            {
                document.getElementById("registrationVerifyErrorLog").style.display = "block";
                document.getElementById("registrationVerifyErrorLog").innerText = data.message;
                document.getElementById("registrationAccountVerifyButton32").disabled = false;
            }
        })
        .catch((error) => {
            console.log(error);
            document.getElementById("registrationVerifyErrorLog").style.display = "block";
            document.getElementById("registrationVerifyErrorLog").innerText = error.message;
            document.getElementById("registrationAccountVerifyButton32").disabled = false;
        });

    }

    const OnVerifyButton = ()=> {
        try {
            document.getElementById("registrationVerifyErrorLog").style.display = "none";
            document.getElementById("registrationVerifyErrorLog").innerText = "";
            document.getElementById("registrationAccountVerifyButton2").disabled = true;

            if(OTP === null)
            {
                const dataFromUser = {email: document.getElementById('emailValue').value};

                fetch(process.env.REACT_APP_BACKEND_DOMAIN + '/forgotpassword', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(dataFromUser)
                })
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data);

                    if(data.message === "SUCCESS"){
                        OTP = data.otp;

                        document.getElementById('emailValue').readOnly = true;
                        document.getElementById("registrationAccountVerifyButton2").disabled = false;

                        document.getElementById('passwordLoginBox').style.display = "flex";

                        document.getElementById("registrationAccountVerifyButton2").innerText = "Verify Me";

                        document.getElementById("verifykeyuser").focus();
                    }else
                    {
                        document.getElementById("registrationVerifyErrorLog").style.display = "block";
                        document.getElementById("registrationVerifyErrorLog").innerText = data.message;
                        document.getElementById("registrationAccountVerifyButton2").disabled = false;
                    }
                    
                })
                .catch((error) => {
                    console.log(error);
                    document.getElementById("registrationVerifyErrorLog").style.display = "block";
                    document.getElementById("registrationVerifyErrorLog").innerText = error.message;
                    document.getElementById("registrationAccountVerifyButton2").disabled = false;
                });
            }else
            {
                const OTPFROMUSER = document.getElementById("verifykeyuser").value;

                console.log(OTPFROMUSER + " === " + OTP);

                if(OTPFROMUSER === OTP)
                {
                    const dataFromUser = {email: document.getElementById('emailValue').value};

                    document.getElementById("registrationVerifyErrorLog").style.display = "none";
                    document.getElementById("registrationVerifyErrorLog").innerText = "";
                    document.getElementById("registrationAccountVerifyButton2").disabled = true;

                    fetch(process.env.REACT_APP_BACKEND_DOMAIN + '/get-student-profile', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(dataFromUser)
                    })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Success:', data);

                        document.getElementById("passwordField").style.display = "flex";
                        document.getElementById("verifykeyuser").readOnly = true;

                        document.getElementById("passwordInputFieldIMP").value = data.password;
                        document.getElementById("registrationAccountVerifyButton2").style.display = "none";
    
                        document.getElementById("passwordInputFieldIMP").focus();

                        PASSWORD = data.password;
                    })
                    .catch((error) => {
                        console.log(error);
                        document.getElementById("registrationVerifyErrorLog").style.display = "block";
                        document.getElementById("registrationVerifyErrorLog").innerText = error.message;
                        document.getElementById("registrationAccountVerifyButton2").disabled = false;
                    });
                }else
                {
                    document.getElementById("registrationVerifyErrorLog").style.display = "block";
                    document.getElementById("registrationVerifyErrorLog").innerText = "Incorrect OTP";
                    document.getElementById("registrationAccountVerifyButton2").disabled = false;
                }
            }
        } catch(error) {
            document.getElementById("registrationVerifyErrorLog").style.display = "block";
            document.getElementById("registrationVerifyErrorLog").innerText = error.message;
            document.getElementById("registrationAccountVerifyButton2").disabled = false;
        }
    }

    const editPassword = () => {
        console.log(document.getElementById("registrationAccountVerifyButton32").innerText);
        
        if(document.getElementById("registrationAccountVerifyButton32").innerText === "Edit") 
        {
            document.getElementById("passwordInputFieldIMP").readOnly = false; 
            document.getElementById("registrationAccountVerifyButton32").innerText = "Update"; 
        } 
        else 
        {
            document.getElementById("registrationAccountVerifyButton32").innerText = "Edit"; 
            document.getElementById("passwordInputFieldIMP").readOnly = true; 

            if(PASSWORD !== document.getElementById("passwordInputFieldIMP").value)
            {
                console.log("Updateing password");
                updatePassword(); 
            }
        }
    }

    return (
        <div className="login">
            <img src={backgroundImage} alt="background" className="login__bg" />

            <form action=""  className="login__form">
                <h1 className="login__title">Forgot Password</h1>
                <h5 className="login__titleH5">Please provide your email address here to confirm your identity. </h5>

                <div className="login__inputs" style={{margin:"0px"}}>
                    <div className="login__box">
                        <input type="email" placeholder="Email ID" id="emailValue" required className="login__input" autoFocus  />
                        <IoMailSharp style={{transform: "scale(1.1)"}} />
                    </div>
                    
                    <div id="passwordLoginBox" style={{display: "none", width: "350px", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                        <h5 className="login__titleH5">An email has been sent to your registered email address. Please check your inbox for the One-Time Password (OTP) and enter it here. </h5>
                        <input type="text" id="verifykeyuser" pattern='^(0|[1-9][0-9]*)$' title="Must contain numberic characters only." style={{textAlign: "center", letterSpacing: "5px", fontWeight: "bolder"}} maxLength="6" minLength="6" placeholder="XXXXXX" required className="login__input" />
                    </div>
                    
                    <div className="login__box" id="passwordField" style={{display:"none", flexDirection: "column", padding: "7.5px", columnGap: "5px", border: "0.1px solid #fff"}}>
                        <h5 className="login__titleH5" style={{width: "100%", textAlign: "left"}}>Password</h5>
                        <div style={{display: "flex", alignItems: "center", flexDirection: "row", justifyContent: "center", alignContent: "center", width: "100%", columnGap: "1.5px"}}>
                            <input type="password" id="passwordInputFieldIMP" readOnly={true} pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" placeholder="Password" required className="login__input" />
                            <FaLock id="RiLockPasswordFill_login" style={{transform: "scale(1.5)"}} onMouseOver={(e)=>{e.target.style.cursor= "pointer"}} onClick={(e)=>{ document.getElementById("passwordInputFieldIMP").type = "text"; document.getElementById("RiLockPasswordFill_login").style.display = "none"; document.getElementById("RiUnLockPasswordFill_login").style.display = "block"; }} />
                            <FaLockOpen id="RiUnLockPasswordFill_login" style={{display: "none", transform: "scale(1.5)"}} onMouseOver={(e)=>{e.target.style.cursor= "pointer"}} onClick={(e)=>{ document.getElementById("passwordInputFieldIMP").type = "password"; document.getElementById("RiUnLockPasswordFill_login").style.display = "none"; document.getElementById("RiLockPasswordFill_login").style.display = "block"; }} />
                            <button type="button" onClick={editPassword} className="login__button" style={{width: "20%", margin: "5px", height: "30px", padding: "0px 10px", fontSize: "12px"}} id="registrationAccountVerifyButton32">Edit</button>
                            <button type="button" onClick={()=>{ var inputField = document.getElementById("passwordInputFieldIMP"); navigator.clipboard.writeText(inputField.value).then(() => { alert('Password copied to clipboard'); }).catch(err => { console.error('Failed to copy text: ', err);});}} className="login__button" style={{width: "20%", margin: "5px 0px", height: "30px", padding: "0px 10px", fontSize: "12px"}}>Copy</button>
                        </div>
                        <button type="button" className="login__button" onClick={()=>{navigate('/login')}} style={{margin: "10px 0px 10px 0px"}} >Login</button>
                    </div>

                    <button type="button" className="login__button" id="registrationAccountVerifyButton2" onClick={OnVerifyButton} style={{marginBottom: "10px"}} >Authenticate Me</button>
                </div>

                <div id="registrationVerifyErrorLog" style={{display:"none", color:"red", textAlign:"center", marginTop:"20px"}} ></div>
            </form>
        </div>
    );
}

export default ForgotPassword;