import React from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you are using React Router for navigation
import './registration.css'; // Import your CSS filess

import backgroundImage from '../../Assets/img/login-bg.png';

import { FaUser } from "react-icons/fa";
import { IoMailSharp } from "react-icons/io5";
import { RiLockPasswordFill } from "react-icons/ri";

function RegistrationForm() {
    const navigate = useNavigate();

    const OnSubmitHandler = () => 
    {
        if(!document.forms['registrationForm'].reportValidity()) return;

        document.getElementById("registrationErrorLog").innerText = "";
        document.getElementById("registrationAccountButton").disabled = true;

        const email = document.getElementById('emailInputFieldIMP').value;
        const data = {email: email};
        // Send the data to the server
        fetch(process.env.REACT_APP_BACKEND_DOMAIN+'/create-first-registration-email-password-name-dob', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            const password = document.getElementById('passwordInputFieldIMP').value;
            const name = document.getElementById('nameInputFieldIMP').value;
            const dob = document.getElementById('dobInputFieldIMP').value;

            if(data.message === "SUCCESS")
            {
                navigate('./verify', {
                    state: {      // You can pass data using the 'state' object
                        name: name, 
                        email: data.email, 
                        password: password, 
                        dob:dob,
                        otp: data.otp
                    },
                  });
            }else
            {
                document.getElementById("registrationErrorLog").innerText = data.message;
                document.getElementById("registrationAccountButton").disabled = false;
            }
        })
        .catch((error) => {
            console.log(error);
            document.getElementById("registrationErrorLog").innerText = error.message;
            document.getElementById("registrationAccountButton").disabled = false;
        });
    }

    return (
        <div>
            <img src={backgroundImage} alt="background" className="login__bg" />
            <section className="container">
                <header>Create an Account</header>
                <div id="containerH5">*Please enter your credentials for creation of your account in Pionverse LMS.</div>
                <form className="form" id="registrationForm">
                    <div className="input-box">
                        <label>Full Name</label>
                        <div className="login__box">
                            <input type="text" id="nameInputFieldIMP" placeholder="Please tell us your full name" min="5" required autoFocus />
                            <FaUser />
                        </div>
                    </div>
                    <div className="input-box">
                        <label>Date of Birth</label>
                        <input type="date" id="dobInputFieldIMP" required />
                    </div>
                    <div className="input-box">
                        <label>Email Address</label>
                        <div className="login__box">
                            <input type="email" id="emailInputFieldIMP" placeholder="Your email id" required />
                            <IoMailSharp />
                        </div>
                    </div>
                    <div className="input-box">
                        <label>Password</label>
                        <div className="login__box">
                            <input type="password" id="passwordInputFieldIMP" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" placeholder="Password" required className="login__input" />
                            <RiLockPasswordFill />
                        </div>
                    </div>
                    <button type="button" id="registrationAccountButton" onClick={OnSubmitHandler}>Create Account</button>
                    <div className="login__register">
                        Already have an account? <span style={{ cursor: 'pointer' }} onClick={() => navigate('/login')}><u>Login</u></span>
                    </div>
                    <div id="registrationErrorLog" style={{color:"red", textAlign:"center", marginTop:"20px"}} ></div>
                </form>
            </section>
        </div>
    );
}

export default RegistrationForm;
