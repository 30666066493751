import { React, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you are using React Router for navigation
import backgroundImage from '../../Assets/img/login-bg.png';
import './login.css'; // Import your CSS files

import { IoMailSharp } from "react-icons/io5";
import { FaLock } from "react-icons/fa";
import { FaLockOpen } from "react-icons/fa";

function LoginPage() {
    const navigate = useNavigate();

    const OnVerifyButton = ()=> {
        try {
            document.getElementById("registrationVerifyErrorLog").innerText = "";
            document.getElementById("registrationAccountVerifyButton").disabled = true;

            const dataFromUser = {email: document.getElementById('emailValue').value, password: document.getElementById('passwordValue').value};

            fetch(process.env.REACT_APP_BACKEND_DOMAIN + '/check-login-credintial', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(dataFromUser)
            })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                if(data.message === "SUCCESS")
                {
                    if(document.getElementById("user-check").checked){
                        localStorage.setItem("rememberloginemail", dataFromUser.email);
                        localStorage.setItem("rememberloginpassword", dataFromUser.password);
                    }

                    navigate('/dashboard', {state : {email: data.email}});
                }else
                {
                    document.getElementById("registrationVerifyErrorLog").innerText = data.message;
                    document.getElementById("registrationAccountVerifyButton").disabled = false;
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("registrationVerifyErrorLog").innerText = error.message;
                document.getElementById("registrationAccountVerifyButton").disabled = false;
            });
        } catch(error) {
            document.getElementById("registrationVerifyErrorLog").innerText = error.message;
            document.getElementById("registrationAccountVerifyButton").disabled = false;
        }
    }

    const checkIfWeRembeberLogins = ()=> {
        if(localStorage.getItem('rememberloginemail') !== null)
        {
            console.log(document.getElementById('emailValue'));
            document.getElementById('emailValue').value = localStorage.getItem('rememberloginemail');
        }

        if(localStorage.getItem('rememberloginpassword') !== null)
        {
            document.getElementById('passwordValue').value = localStorage.getItem('rememberloginpassword');
        }
    }

    useEffect(() => {
        checkIfWeRembeberLogins();
     });

    return (
        <div className="login">
            <img src={backgroundImage} alt="background" className="login__bg" />

            <form action=""  className="login__form">
                <h1 className="login__title">Pioneverse LMS</h1>
                <h5 className="login__titleH5">*Please enter your credentials here for student dashboard. </h5>

                <div className="login__inputs">
                    <div className="login__box">
                        <input type="email" placeholder="Email ID" id="emailValue" required className="login__input"  on />
                        <IoMailSharp />
                    </div>

                    <div className="login__box" style={{display:"flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                        <input type="password" placeholder="Password" id="passwordValue" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"  required className="login__input" />
                        <FaLock id="RiLockPasswordFill_login" onMouseOver={(e)=>{e.target.style.cursor= "pointer"}} onClick={(e)=>{ document.getElementById("passwordValue").type = "text"; document.getElementById("RiLockPasswordFill_login").style.display = "none"; document.getElementById("RiUnLockPasswordFill_login").style.display = "block"; }} />
                        <FaLockOpen id="RiUnLockPasswordFill_login" style={{display: "none"}} onMouseOver={(e)=>{e.target.style.cursor= "pointer"}} onClick={(e)=>{ document.getElementById("passwordValue").type = "password"; document.getElementById("RiUnLockPasswordFill_login").style.display = "none"; document.getElementById("RiLockPasswordFill_login").style.display = "block"; }} />
                    </div>
                </div>

                <div className="login__check">
                    <div className="login__check-box">
                        <input type="checkbox" className="login__check-input" id="user-check" />
                        <label htmlFor="user-check" className="login__check-label">Remember me</label>
                    </div>

                    <span className="login__forgot" style={{ cursor: 'pointer' }} onClick={() => navigate('/login/forgotpassword')}>Forgot Password?</span>
                </div>

                <button type="button" className="login__button" id="registrationAccountVerifyButton" onClick={OnVerifyButton}>Login</button>

                <div className="login__register">
                    Don't have an account? <span style={{ cursor: 'pointer' }} onClick={() => navigate('/registration')}><u>Register</u></span>
                </div>

                <div id="registrationVerifyErrorLog" style={{color:"red", textAlign:"center", marginTop:"20px"}} ></div>
            </form>
        </div>
    );
}

export default LoginPage;