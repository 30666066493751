import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Assuming you are using React Router for navigation
import backgroundImage from '../../Assets/img/login-bg.png';
import chapter1 from '../../Assets/img/chapter1class6.png';
import chapter2 from '../../Assets/img/chapter2class6.png';
import './dashboard.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

function Dashboard() {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    console.log(state);

    var sucessLogindetail = {};

    useEffect(() => {
        const checkFullAccountSupport = () => {
            try {
                const data = { email: state.email };
                fetch(process.env.REACT_APP_BACKEND_DOMAIN + '/check-full-account-profile', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data);
                    if (data.message === "SUCCESS") {
                        //saveStudentDetails(data.email, data.name);
                        sucessLogindetail = { email: data.email, name: data.name };
                    } else if (data.message === "FAILED") {
                        navigate('/registration/studentdetails', { state: { email: data.email } });
                    } else {
                        navigate('/login');
                    }
                })
                .catch((error) => {
                    console.log(error);
                    navigate('/login');
                });
            } catch (error) {
                console.log(error);
                navigate('/login');
            }
        };

        setTimeout(() => {
            checkFullAccountSupport();
        }, 100);
    }, [navigate, state.email]);

    function saveStudentDetailsAndLoadUrl(url) {
        let email = sucessLogindetail.email;
        let name = sucessLogindetail.name;

        window.location.href = encodeURI(url + "?e=" + email + "&n=" + name);
    }   

    return (
        <div>
            <img src={backgroundImage} alt="background" className="login__bg" />
            <section className="dashboard">
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "1.5rem" }}>
                    <div id="" style={{ width: "300px" }}></div>
                    <header>Pioneverse Education</header> 
                    <button 
                        type="button" 
                        onClick={() => { navigate('/registration/studentdetails', { state: { email: state.email } }); }} 
                        className="update-button"
                    >
                        Update
                    </button>
                </div>
                <header>Class 6th</header>
                <div id="containerH5">Chapter wise content</div>
                <div className="cards-container" style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                    <Card sx={{ maxWidth: 345, margin: "20px" }} className="card">
                        <CardActionArea>
                            <CardMedia component="img" height="140" image={chapter1} alt="Chapter 1" />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Chapter 1: The Cell
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Cells are the most basic unit of life. All cells have a membrane that separates them from the outside world. Although cells are small, they are not simple. Cells contain different components, such as the cytoplasm, ribosomes, and genetic information in the form of DNA.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button id="viewInMetaverse" size="small" onClick={(e) => { e.target.disabled = true; e.target.style.backgroundColor = 'gray'; e.target.innerText = "Please Wait..."; saveStudentDetailsAndLoadUrl("https://academics-meta.pioneverse.com/biology/6/1/index.html"); }}>View in Metaverse</Button>
                        </CardActions>
                    </Card>
                    <Card sx={{ maxWidth: 345, margin: "20px" }} className="card">
                        <CardActionArea>
                            <CardMedia component="img" height="140" image={chapter2} alt="Chapter 2" />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Chapter 2: The Leaf
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    The leaf consist of a broad, flat part called the lamina, which is joined to the rest of the plant by a leaf stalk or petiole. Running through the petiole are vascular bundles, which then form the veins in the leaf. Although a leaf looks thin, it's made up of several layers of cells.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button id="viewInMetaverse" size="small" onClick={(e) => { e.target.disabled = true; e.target.style.backgroundColor = 'gray'; e.target.innerText = "Please Wait..."; saveStudentDetailsAndLoadUrl("https://academics-meta.pioneverse.com/biology/6/2/index.html"); }}>View in Metaverse</Button>
                        </CardActions>
                    </Card>
                </div>
            </section>
        </div>
    );
}

export default Dashboard;
