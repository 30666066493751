import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Assuming you are using React Router for navigation
import backgroundImage from '../../Assets/img/login-bg.png';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function StudentDetails() {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    console.log(state);

    const [classvalue, setClassValue] = useState('');
    const [sectionvalue, setSectionValue] = useState('');
    const [board, setBoardValue] = useState('');
    const [fsubject, setfsubjectSValue] = useState([{title: 'Biology', id: 1}]);
    const [referalcode, setReferalCodeValue]  = useState('');

    const CreateStudentDetials = () => {
        try {
            document.getElementById("registrationErrorLog").innerText = "";
            document.getElementById("registrationAccountButton").disabled = true;

            if(classvalue.length === 0 || sectionvalue.length === 0 || board.length === 0 || fsubject.length === 0) {
                console.log(classvalue, sectionvalue, board, fsubject, referalcode);

                document.getElementById("registrationErrorLog").innerText = "Invalid Inputs";
                document.getElementById("registrationAccountButton").disabled = false;
                return;
            }

            console.log(classvalue, sectionvalue, board, fsubject, referalcode);

            const data = {email: state.email, classvalue: classvalue, section: sectionvalue, board: board, fsubject: fsubject, referalcode: referalcode};
            // Send the data to the server
            fetch(process.env.REACT_APP_BACKEND_DOMAIN + '/update-student-profile', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                if(data.message === "SUCCESS")
                {
                    navigate('/dashboard', { state: {email: data.email}});
                }else
                {
                    document.getElementById("registrationErrorLog").innerText = data.message;
                    document.getElementById("registrationAccountButton").disabled = false;
                }
            })
            .catch((error) => {
                console.log(error);
                document.getElementById("registrationErrorLog").innerText = error.message;
                document.getElementById("registrationAccountButton").disabled = false;
            });
        } catch(error) {
            console.log(error);
            document.getElementById("registrationErrorLog").innerText = error.message;
            document.getElementById("registrationAccountButton").disabled = false;
        }
    }

    const boardOptions = [
        { label: 'Central Board of Secondary Education (CBSE)', id: 1 },
        { label: 'Indian Certificate of Secondary Education (ICSE)', id: 2 },
        { label: 'National Institute of Open Schooling (NIOS)', id: 3 },
        { label: 'Maharashtra State Board of Secondary and Higher Secondary Education', id: 4 },
        { label: 'Tamil Nadu State Board of Secondary Education', id: 5 },
        { label: 'Uttar Pradesh Madhyamik Shiksha Parishad (UPMSP)', id: 6 },
        { label: 'West Bengal Council of Higher Secondary Education (WBCHSE)', id: 7 },
        { label: 'Council for the Indian School Certificate Examinations (CISCE)', id: 8 },
        { label: 'Rajasthan Board of Secondary Education (RBSE)', id: 9 },
        { label: 'Karnataka Secondary Education Examination Board (KSEEB)', id: 10 },
        { label: 'Andhra Pradesh Board of Secondary Education (BSEAP)', id: 11 },
        { label: 'Telangana State Board of Intermediate Education (TSBIE)', id: 12 },
        { label: 'Kerala State Education Board', id: 13 },
        { label: 'Gujarat Secondary and Higher Secondary Education Board (GSEB)', id: 14 },
        { label: 'Punjab School Education Board (PSEB)', id: 15 },
        { label: 'Bihar School Examination Board (BSEB)', id: 16 },
        { label: 'Haryana Board of School Education (HBSE)', id: 17 },
        { label: 'Madhya Pradesh Board of Secondary Education (MPBSE)', id: 18 },
        { label: 'Jharkhand Academic Council (JAC)', id: 19 },
        { label: 'Himachal Pradesh Board of School Education (HPBOSE)', id: 20 },
        { label: 'Chhattisgarh Board of Secondary Education (CGBSE)', id: 21 },
        { label: 'Odisha Board of Secondary Education (BSE Odisha)', id: 22 },
        { label: 'Uttarakhand Board of School Education (UBSE)', id: 23 },
        { label: 'Assam Higher Secondary Education Council (AHSEC)', id: 24 },
        { label: 'Goa Board of Secondary & Higher Secondary Education', id: 25 },
        { label: 'Manipur Council of Higher Secondary Education (CHSEM)', id: 26 },
        { label: 'Meghalaya Board of School Education (MBOSE)', id: 27 },
        { label: 'Mizoram Board of School Education (MBSE)', id: 28 },
        { label: 'Nagaland Board of School Education (NBSE)', id: 29 },
        { label: 'Tripura Board of Secondary Education (TBSE)', id: 30 },
        { label: 'Sikkim Board of Secondary Education (SBSE)', id: 31 },
        { label: 'Arunachal Pradesh Board of Secondary Education (APBSE)', id: 32 },
        { label: 'Andaman and Nicobar Islands Board of Secondary Education (ANISE)', id: 33 },
        { label: 'Lakshadweep Board of Secondary Education (LBSE)', id: 34 },
        { label: 'Dadra and Nagar Haveli Board of Secondary & Higher Secondary Education', id: 35 },
        { label: 'Daman and Diu Board of Secondary & Higher Secondary Education', id: 36 },
        { label: 'Chandigarh Board of Secondary Education (CBSE)', id: 37 },
        { label: 'National Institute of Open Schooling (NIOS) II', id: 38 },
        { label: 'Council for the Indian School Certificate Examinations (CISCE) II', id: 39 },
        { label: 'International Baccalaureate (IB)', id: 40 },
        { label: 'Cambridge Assessment International Education (CAIE)', id: 41 },
        { label: 'National Institute of Open Schooling (NIOS) III', id: 42 },
        { label: 'Kerala Higher Secondary Examination Board', id: 43 },
        { label: 'Jammu and Kashmir State Board of School Education (JKBOSE)', id: 44 },
        { label: 'Ladakh School Education Board (LSEB)', id: 45 },
        { label: 'Central Tibetan Schools Administration (CTSA)', id: 46 },
        { label: 'Army Welfare Education Society (AWES)', id: 47 },
        { label: 'Navodaya Vidyalaya Samiti (NVS)', id: 48 },
        { label: 'Kendriya Vidyalaya Sangathan (KVS)', id: 49 },
        { label: 'Bhutan Council for School Examinations and Assessment (BCSEA)', id: 50 }
      ];

    const subjectOptions = [
        { title: "Biology", id: 1},
        { title: "Physics", id: 2},
        { title: "Mathematics", id: 3},
        { title: "Chemistry", id: 4}
    ];

    const classOptions = [{label:"I", id: 1},{label:"II", id: 2},{label:"III", id: 3},{label:"IV", id: 4},{label:"V", id: 5},{label:"VI", id: 7},{label:"VII", id: 8},{label:"IX", id: 9},{label:"X", id: 10},{label:"XI", id: 11},{label:"XII", id: 12}]
    const section = [{label:"A",id:1},{label:"B",id:2},{label:"C",id:3},{label:"D",id:4},{label:"E",id:5}]

    useEffect(() => {
        setTimeout(() => {
            const FetchStudentDetials = () => {
                try {
                    document.getElementById("registrationErrorLog").innerText = "";
                    document.getElementById("registrationAccountButton").style.display = "none";
        
                    const data = {email: state.email};
                    // Send the data to the server
                    fetch(process.env.REACT_APP_BACKEND_DOMAIN +'/get-student-profile', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(data)
                    })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Success:', data);
                        if(data.message === "SUCCESS")
                        {
                            setClassValue(data.classvalue);
                            setSectionValue(data.section);
                            setBoardValue(data.board);
                            setReferalCodeValue(data.referalcode);
                            setfsubjectSValue(data.fsubject);
                        }else
                        {
                            document.getElementById("registrationErrorLog").innerText = data.message;
        
                            if(data.message === "Your email is not registered in our database. Create your pioneverse account first.") {
                                document.getElementById("registrationAccountButton2").style.display = "flex";
                            }
                        }

                        document.getElementById("registrationAccountButton").style.display = "block";
                    })
                    .catch((error) => {
                        console.log(error);
                        document.getElementById("registrationErrorLog").innerText = error.message;
                    });
                } catch(error) {
                    console.log(error);
                    document.getElementById("registrationErrorLog").innerText = error.message;
                }
            }
            
            FetchStudentDetials();
        }, 100);
    }, []);

    return (
        <div>
            <img src={backgroundImage} alt="background" className="login__bg" />
            <section className="containerstudentdetail">
                <header>Tell us more about youself</header>
                <div id="containerH5">*Kindly share some details concerning your student background and personal experiences.</div>
                <form>
                    <div style={{display:"flex", flexDirection: "row", justifyContent: "space-evenly", margin: "20px 5px 0px 0px"}}>
                        <Autocomplete
                            disablePortal
                            id="class"
                            value={classvalue}
                            onChange={(event, value) => setClassValue(value)} 
                            options={classOptions}
                            sx={{ width: 270, margin: "0px 5px 0px 0px"}}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} label="Class" style={{filter: "brightness(0) invert(0.9) contrast(200%)"}} />}
                        />
                        <Autocomplete
                            disablePortal
                            id="section"
                            options={section}
                            value={sectionvalue}
                            sx={{ width: 120}}
                            onChange={(event, value) => setSectionValue(value)} 
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) => <TextField {...params} label="Section" style={{filter: "brightness(0) invert(0.9) contrast(200%)"}} />}
                        />
                    </div>

                    <Autocomplete
                        disablePortal
                        id="board"
                        options={boardOptions}
                        value={board}
                        onChange={(event, value) => setBoardValue(value)} 
                        sx={{ width: 390, margin: "10px 0px 10px 0px"}}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => <TextField {...params} label="Your education board" style={{filter: "brightness(0) invert(0.9) contrast(200%)"}} />}
                    />

                    <Autocomplete
                        multiple
                        id="fsubject"
                        options={subjectOptions}
                        disableCloseOnSelect
                        onChange={(event, value) => setfsubjectSValue(value)} // Ensure state is updated
                        getOptionLabel={(option) => option.title}
                        value={fsubject} // Controlled value
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.title}
                            </li>
                        )}
                        style={{ width: 390, margin: "10px 0px" }}
                        renderInput={(params) => (
                            <TextField {...params} label="Favourite subjects" placeholder="Subjects" style={{width: 390,filter: "brightness(0) invert(0.9) contrast(200%)"}} />
                        )}
                    />

                    <TextField id="referalcode" value={referalcode} onChange={(event) => setReferalCodeValue(event.target.value)}  label="School referal code" variant="outlined" style={{filter: "brightness(0) invert(0.9) contrast(200%)", width:"390px"}} />
                    
                    <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <button type="button" id="registrationAccountButton" onClick={CreateStudentDetials} style={{display: "block", height: "55px", width: "47%", color: "#fff", fontSize: "0.8rem", fontWeight: "400", marginTop: "10px", border: "none", cursor: "pointer", transition: "all 0.2s ease", background: "#601EF9"}} >Update Student Details</button>
                        <button type="button" onClick={()=>{navigate('/dashboard', {state:{email:state.email}})}} style={{ display: "block", height: "55px", width: "47%", color: "#fff", fontSize: "0.8em", fontWeight: "400", marginTop: "10px", border: "none", cursor: "pointer", transition: "all 0.2s ease", background: "#601EF9"}} >Cancel & Dashboard</button>
                    </div>
                    <button type="button" id="registrationAccountButton2" onClick={()=>{navigate('/registration')}} style={{display:"none",height: "55px", width: "100%", color: "#fff", fontSize: "1rem", fontWeight: "400", marginTop: "10px", border: "none", cursor: "pointer", transition: "all 0.2s ease", background: "#601EF9"}} >Create an account</button>
                    <div id="registrationErrorLog" style={{color:"red", textAlign:"center", marginTop:"20px"}} ></div>
                </form>
            </section>
        </div>
    );
}

export default StudentDetails;
