import React from 'react';
import backgroundImage from '../../Assets/img/login-bg.png';

function NotFound() {
    return (
        <div className="notfound">
            <img src={backgroundImage} alt="background" className="login__bg" />
            <div style={{width:"100vw", fontSize: "5vw", textAlign: "center", paddingTop: "10vh", fontWeight: "bolder", color: "white"}}>Error 404</div>
        </div>
    );
}

export default NotFound;
