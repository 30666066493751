import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css'; 

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Pioneverse from './Components/main';
import RegistrationForm from './Pages/registration/registration.js';
import LoginPage from './Pages/login/login.js';
import MailVerification from './Pages/mailVerification/mailverification.js';
import StudentDetails from './Pages/studentDetail/studentdetails.js';
import Dashboard from './Pages/dashboard/dashboard.js';
import NotFound from './Pages/notfound/notfound.js';
import ForgotPassword from './Pages/forgotPassword/forgotpassword.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
      <Routes>
            <Route exact path="/" element={<Pioneverse />} />
            <Route path="/registration" element={<RegistrationForm />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/login/forgotpassword" element={<ForgotPassword />} />
            <Route path="/registration/verify" element={<MailVerification />} />
            <Route path="/registration/studentdetails" element={<StudentDetails />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </BrowserRouter>
);