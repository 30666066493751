import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Assuming you are using React Router for navigation

import backgroundImage from '../../Assets/img/login-bg.png';

function MailVerification() {
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state;
    console.log(state);

    const OnVerifyButton = ()=> {
        try {
            if(!document.forms['registrationForm'].reportValidity()) return;

            document.getElementById("registrationVerifyErrorLog").innerText = "";
            document.getElementById("registrationAccountVerifyButton").disabled = true;

            const verifyKeyUser = document.getElementById("verifykeyuser").value;

            if(verifyKeyUser === state.otp) {
                const data = {email: state.email, dob: state.dob, name: state.name, password: state.password};

                fetch(process.env.REACT_APP_BACKEND_DOMAIN+'/create-first-registrartion-verification', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                })
                .then(response => response.json())
                .then(data => {
                    console.log('Success:', data);
                    if(data.message === "SUCCESS")
                    {
                        navigate('/dashboard', {state : {email: data.email}});
                    }else
                    {
                        document.getElementById("registrationVerifyErrorLog").innerText = data.message;
                        document.getElementById("registrationAccountVerifyButton").disabled = false;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    document.getElementById("registrationVerifyErrorLog").innerText = error.message;
                    document.getElementById("registrationAccountVerifyButton").disabled = false;
                });
            }else {
                document.getElementById("registrationVerifyErrorLog").innerText = "Verification code is invalid.";
                document.getElementById("registrationAccountVerifyButton").disabled = false;
            }
        } catch(error) {
            document.getElementById("registrationVerifyErrorLog").innerText = error.message;
            document.getElementById("registrationAccountVerifyButton").disabled = false;
        }
    }

    return (
        <div>
            <img src={backgroundImage} alt="background" className="login__bg" />
            <section className="container">
                <header>Registration Verification</header>
                <div id="containerH5">We sent you a verification email at <b>{state.email}</b>. It's a six-digit numeric code. Please provide us with the verification code for Pioneverse LMS Dashboard..</div>
                <form className="form" id="registrationForm">
                    <div className="input-box">
                        <div className="login__box" style={{columnGap: "0", padding: "0"}}>
                            <input type="text" id="verifykeyuser" pattern='^(0|[1-9][0-9]*)$' title="Must contain numberic characters only." autoFocus style={{textAlign: "center", letterSpacing: "5px", fontWeight: "bolder"}} maxLength="6" minLength="6" placeholder="XXXXXX" required className="login__input" />
                        </div>
                    </div>
                    <button type="button" onClick={OnVerifyButton} id="registrationAccountVerifyButton">Verify</button>
                    <div className="login__register" style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <span style={{ cursor: 'pointer' }}>Didn't recieve code?  <u>Resend</u></span>
                        <span style={{ cursor: 'pointer' }} onClick={()=>{navigate('/registration')}}><u>Back</u></span>
                    </div>
                    <div id="registrationVerifyErrorLog" style={{color:"red", textAlign:"center", marginTop:"20px"}} ></div>
                </form>
            </section>
        </div>
    );
}

export default MailVerification;
