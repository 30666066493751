import React from 'react';
import { useNavigate } from 'react-router-dom';

import backgroundImage from '../Assets/img/login-bg.png';
import logo from '../Assets/img/logo.png';
import './main.css';

function Pioneverse() {
    const navigate = useNavigate();

    // Function to simulate checking if login info is saved
    const checkIfLoginInfoSaved = () => {
        // Replace this with your actual logic to check if login info is saved
        // For example, you might check if there's a token in local storage or session storage
        const isLoginInfoSaved = localStorage.getItem('token') !== null;

        if (isLoginInfoSaved) {
            // Redirect to one link if login info is saved
            navigate('./login');
        } else {
            // Redirect to another link if login info is not saved
            navigate('./registration');
        }
    };

    return (
        <div className="landingpage">
            <img src={backgroundImage} alt="background" className="login__bg" />
            <div id="landingpagenavbar">
                <img src={logo} alt="logo" id="landingpagelogo"/>
                <div id="options">
                    <div id="optionsitem" className='button-36'>
                        Home
                    </div>
                    <div id="optionsitem">
                        About
                    </div>
                    <div id="optionsitem">
                        News
                    </div>
                    <div id="optionsitem">
                        Team
                    </div>
                    <div id="optionsitem">
                        Blog
                    </div>
                </div>
            </div>

            <div id="middlebar">
                <div id="middlebarleft">
                    <div id="middlebarHeading">Pioneverse Education Platform</div>
                    <div id="middlebarSubHeading">Join the Pioneverse Metaverse: Where Learning Feels Like Home</div>
                    <div id="middlebarbutton" className='button-36' onClick={checkIfLoginInfoSaved}>
                        Try it
                    </div>
                </div>

                <div id="middlebarright">
                    <div id="middlebarrightSubHeading">Enter our Metaverse community, where learning is personal and connections are meaningful. Explore diverse realms, collaborate with fellow learners, and embark on a journey of growth, discovery, and shared humanity. Welcome home.</div>
                    <div id="iddlebarrightbuttongroup">
                        <div id="middlebarrightbutton" className='button-36' onClick={()=>{navigate('./login')}}>
                            Login as Student
                        </div>
                        <div id="middlebarrightbutton" className='button-36' onClick={()=>{window.location.href = "https://biology-6-1.pioneverse.com/"}}>
                            Login as Teacher
                        </div>
                    </div>
                </div>
                
            </div>

            <div id="footerregion">
                <div id="footregionbutton" className='footerregionbuttonactive'></div>
                <div id="footregionbutton" className='footerregionbuttondeactive'></div>
                <div id="footregionbutton" className='footerregionbuttondeactive'></div>
                <div id="footregionbutton" className='footerregionbuttondeactive'></div>
                <div id="footregionbutton" className='footerregionbuttondeactive'></div>
            </div>
            

        </div>
    );
}

export default Pioneverse;
